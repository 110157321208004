<template>
  <div class="tax-trusting-confirmation screen-body--bg">
    <div class="page-body">
      <div
        class="tax-trusting-confirmation__introduce tax-trusting-confirmation__text"
        v-html="nl2br($t('desc_tax_return_introduce', {
          financial_year: $route.query.financial_year,
          financial_year_ja: $route.query.financial_year_ja
        }))"
      />
      <div class="tax-trusting-confirmation__introduce tax-trusting-confirmation__introduce--sub tax-trusting-confirmation__text">
        <div>{{ $t('desc_tax_return_introduce_sub_1') }}</div>
        <div>
          {{ $t('desc_tax_return_introduce_sub_2_1') }}<a
            href="javascript:void(0)"
            @click="$router.push(changeEmailRoute)"
          >{{ $t('common_here') }}</a>{{ $t('desc_tax_return_introduce_sub_2_2') }}
        </div>
      </div>
      <tax-contact-block />
    </div>
    <div class="page-footer">
      <div>
        <button
          class="btn btn-primary"
          @click="triggerAction(true)"
        >
          WEB_TAX_TRUSTED
        </button>
      </div>
      <div>
        <button
          class="btn btn-gray"
          @click="triggerAction(false)"
        >
          WEB_TAX_NOT_TRUSTED
        </button>
      </div>
      <br>
      <div class="tax-trusting-confirmation__submit">
        <button
          class="btn btn-primary"
          :disabled="!canSubmit"
          @click="registerSession(true)"
          v-text="nl2br($t('confirmation_report_submit'))"
        />
      </div>
      <div class="tax-trusting-confirmation__back">
        <button
          class="btn btn-gray"
          :disabled="!canSubmit"
          @click="registerSession(false)"
          v-text="nl2br($t('confirmation_report_back'))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WebviewMixin from '@/mixins/webview.mixin'
import * as EmailChangeType from '@/enums/email-change-type.enum'
import * as UserLoginType from '@/enums/user-register-type.enum'
import {mapGetters} from 'vuex'
import {nl2br} from '@/utils/string.util'
import {TaxService} from '@/services/tax.service'
import TaxContactBlock from '@/views/pages/Tax/components/TaxContactBlock'
import {triggerMobileAction} from '@/utils/webview.util'
import {SAVE_APP_LOG} from '@/enums/mobile-event.enum'
import {WEB_TAX_NOT_TRUSTED, WEB_TAX_TRUSTED} from '@/enums/app-log-action.enum'

export default {
  name: 'TaxTrustingConfirmation',
  components: {TaxContactBlock},
  mixins: [WebviewMixin],
  data () {
    return {
      EmailChangeType,
      TaxContactBlock,
    }
  },
  computed: {
    canSubmit () {
      return this.hasToken && !this.loading
    },
    ...mapGetters({
      hasToken: 'auth/hasToken',
      loading: 'loadingAny',
      currentUser: 'auth/currentUser'
    }),
    changeEmailRoute () {
      const isLoginWithSns = this.currentUser.type === UserLoginType.SNS
      const routeName = isLoginWithSns ? this.Page.ChangeEmailEmail : this.Page.ChangeEmail
      return {
        name: routeName,
        query: {
          type: EmailChangeType.TAX,
        }
      }
    },
  },
  methods: {
    nl2br,
    triggerAction (isTrusted) {
      triggerMobileAction(SAVE_APP_LOG, {
        action: isTrusted ? WEB_TAX_TRUSTED : WEB_TAX_NOT_TRUSTED,
        item_id: Number(this.$route.params.id) || 0,
      })
    },
    async registerSession (isTrusted) {
      try {
        const res = await TaxService.registerSession(this.$route.params.id, isTrusted)
        // if register success
        if (Object.keys(res).length > 0) {
          triggerMobileAction(SAVE_APP_LOG, {
            action: isTrusted ? WEB_TAX_TRUSTED : WEB_TAX_NOT_TRUSTED,
            item_id: Number(this.$route.params.id) || 0,
          })

          await this.$router.replace({
            name: isTrusted ? this.Page.TaxDocumentChecking : this.Page.TaxTrustingConfirmationForce,
            params: {...this.$route.params},
            query: {...this.$route.query}
          }).catch(err => err)
        }
      } catch (e) {
        throw e
      }
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
.tax-trusting-confirmation {
  font-size: 14px;
  padding: 30px 20px 16px;

  .page-body {
    margin-bottom: 40px;
  }

  &__introduce {
    margin-bottom: 24px;

    &--sub {
      margin-bottom: 29px;
    }

    a {
      color: #0AA9F4;
    }
  }

  &__text {
    line-height: 24px;
    color: #555555;
  }

  &__submit {
    padding-bottom: 10px;
  }

  .btn {
    border-radius: 5px;
  }
}
</style>
